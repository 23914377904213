meta.foundation-version {
  font-family: "/5.5.3/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0; }

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.0625em)/";
  width: 40.0625em; }

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40.0625em) and (max-width:80em)/";
  width: 40.0625em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:80.0625em)/";
  width: 80.0625em; }

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:80.0625em) and (max-width:90em)/";
  width: 80.0625em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.0625em)/";
  width: 90.0625em; }

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width: 90.0625em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.0625em)/";
  width: 120.0625em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  font-size: 100%; }

body {
  background: #fff;
  color: #222;
  cursor: auto;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.hide {
  display: none; }

.invisible {
  visibility: hidden; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

.row {
  margin: 0 auto;
  max-width: 67.5rem;
  width: 100%; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column,
  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    margin: 0 0;
    max-width: none;
    width: auto; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      margin: 0;
      max-width: none;
      width: auto; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column,
.columns {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  float: left; }

.column + .column:last-child,
.columns + .column:last-child, .column +
.columns:last-child,
.columns +
.columns:last-child {
  float: right; }

.column + .column.end,
.columns + .column.end, .column +
.columns.end,
.columns +
.columns.end {
  float: left; }

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .small-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto; }
  .small-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto; }
  .small-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto; }
  .small-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto; }
  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .small-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto; }
  .small-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto; }
  .small-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto; }
  .small-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto; }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .small-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto; }
  .small-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto; }
  .small-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto; }
  .small-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto; }
  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .small-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto; }
  .small-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto; }
  .small-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto; }
  .small-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .small-1 {
    width: 8.3333333333%; }
  .small-2 {
    width: 16.6666666667%; }
  .small-3 {
    width: 25%; }
  .small-4 {
    width: 33.3333333333%; }
  .small-5 {
    width: 41.6666666667%; }
  .small-6 {
    width: 50%; }
  .small-7 {
    width: 58.3333333333%; }
  .small-8 {
    width: 66.6666666667%; }
  .small-9 {
    width: 75%; }
  .small-10 {
    width: 83.3333333333%; }
  .small-11 {
    width: 91.6666666667%; }
  .small-12 {
    width: 100%; }
  .small-offset-0 {
    margin-left: 0 !important; }
  .small-offset-1 {
    margin-left: 8.3333333333% !important; }
  .small-offset-2 {
    margin-left: 16.6666666667% !important; }
  .small-offset-3 {
    margin-left: 25% !important; }
  .small-offset-4 {
    margin-left: 33.3333333333% !important; }
  .small-offset-5 {
    margin-left: 41.6666666667% !important; }
  .small-offset-6 {
    margin-left: 50% !important; }
  .small-offset-7 {
    margin-left: 58.3333333333% !important; }
  .small-offset-8 {
    margin-left: 66.6666666667% !important; }
  .small-offset-9 {
    margin-left: 75% !important; }
  .small-offset-10 {
    margin-left: 83.3333333333% !important; }
  .small-offset-11 {
    margin-left: 91.6666666667% !important; }
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none; }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left; }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right; }
  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 0;
    padding-right: 0;
    float: left; } }

@media only screen and (min-width: 40.0625em) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto; }
  .medium-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto; }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto; }
  .medium-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto; }
  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto; }
  .medium-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto; }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto; }
  .medium-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto; }
  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto; }
  .medium-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto; }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto; }
  .medium-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto; }
  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto; }
  .medium-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto; }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto; }
  .medium-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .medium-1 {
    width: 8.3333333333%; }
  .medium-2 {
    width: 16.6666666667%; }
  .medium-3 {
    width: 25%; }
  .medium-4 {
    width: 33.3333333333%; }
  .medium-5 {
    width: 41.6666666667%; }
  .medium-6 {
    width: 50%; }
  .medium-7 {
    width: 58.3333333333%; }
  .medium-8 {
    width: 66.6666666667%; }
  .medium-9 {
    width: 75%; }
  .medium-10 {
    width: 83.3333333333%; }
  .medium-11 {
    width: 91.6666666667%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-0 {
    margin-left: 0 !important; }
  .medium-offset-1 {
    margin-left: 8.3333333333% !important; }
  .medium-offset-2 {
    margin-left: 16.6666666667% !important; }
  .medium-offset-3 {
    margin-left: 25% !important; }
  .medium-offset-4 {
    margin-left: 33.3333333333% !important; }
  .medium-offset-5 {
    margin-left: 41.6666666667% !important; }
  .medium-offset-6 {
    margin-left: 50% !important; }
  .medium-offset-7 {
    margin-left: 58.3333333333% !important; }
  .medium-offset-8 {
    margin-left: 66.6666666667% !important; }
  .medium-offset-9 {
    margin-left: 75% !important; }
  .medium-offset-10 {
    margin-left: 83.3333333333% !important; }
  .medium-offset-11 {
    margin-left: 91.6666666667% !important; }
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none; }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left; }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right; }
  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto; } }

@media only screen and (min-width: 80.0625em) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto; }
  .large-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto; }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto; }
  .large-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto; }
  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto; }
  .large-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto; }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto; }
  .large-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto; }
  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto; }
  .large-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto; }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto; }
  .large-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto; }
  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto; }
  .large-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto; }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto; }
  .large-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .large-1 {
    width: 8.3333333333%; }
  .large-2 {
    width: 16.6666666667%; }
  .large-3 {
    width: 25%; }
  .large-4 {
    width: 33.3333333333%; }
  .large-5 {
    width: 41.6666666667%; }
  .large-6 {
    width: 50%; }
  .large-7 {
    width: 58.3333333333%; }
  .large-8 {
    width: 66.6666666667%; }
  .large-9 {
    width: 75%; }
  .large-10 {
    width: 83.3333333333%; }
  .large-11 {
    width: 91.6666666667%; }
  .large-12 {
    width: 100%; }
  .large-offset-0 {
    margin-left: 0 !important; }
  .large-offset-1 {
    margin-left: 8.3333333333% !important; }
  .large-offset-2 {
    margin-left: 16.6666666667% !important; }
  .large-offset-3 {
    margin-left: 25% !important; }
  .large-offset-4 {
    margin-left: 33.3333333333% !important; }
  .large-offset-5 {
    margin-left: 41.6666666667% !important; }
  .large-offset-6 {
    margin-left: 50% !important; }
  .large-offset-7 {
    margin-left: 58.3333333333% !important; }
  .large-offset-8 {
    margin-left: 66.6666666667% !important; }
  .large-offset-9 {
    margin-left: 75% !important; }
  .large-offset-10 {
    margin-left: 83.3333333333% !important; }
  .large-offset-11 {
    margin-left: 91.6666666667% !important; }
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none; }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left; }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right; }
  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto; } }

/* small displays */
@media only screen {
  .show-for-small-only, .show-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }
  .hide-for-small-only, .hide-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }
  .visible-for-small-only, .visible-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }
  .hidden-for-small-only, .hidden-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }
  table.show-for-small-only, table.show-for-small-up, table.show-for-small, table.show-for-small-down, table.hide-for-medium-only, table.hide-for-medium-up, table.hide-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }
  thead.show-for-small-only, thead.show-for-small-up, thead.show-for-small, thead.show-for-small-down, thead.hide-for-medium-only, thead.hide-for-medium-up, thead.hide-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }
  tbody.show-for-small-only, tbody.show-for-small-up, tbody.show-for-small, tbody.show-for-small-down, tbody.hide-for-medium-only, tbody.hide-for-medium-up, tbody.hide-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }
  tr.show-for-small-only, tr.show-for-small-up, tr.show-for-small, tr.show-for-small-down, tr.hide-for-medium-only, tr.hide-for-medium-up, tr.hide-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }
  th.show-for-small-only, td.show-for-small-only, th.show-for-small-up, td.show-for-small-up, th.show-for-small, td.show-for-small, th.show-for-small-down, td.show-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.hide-for-medium-up, td.hide-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* medium displays */
@media only screen and (min-width: 40.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.show-for-medium-only, table.show-for-medium-up, table.show-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.show-for-medium-only, thead.show-for-medium-up, thead.show-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.show-for-medium-only, tbody.show-for-medium-up, tbody.show-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.show-for-medium-only, tr.show-for-medium-up, tr.show-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.show-for-medium-only, td.show-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.show-for-medium, td.show-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* large displays */
@media only screen and (min-width: 80.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.show-for-large-only, table.show-for-large-up, table.show-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.show-for-large-only, thead.show-for-large-up, thead.show-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.show-for-large-only, tbody.show-for-large-up, tbody.show-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.show-for-large-only, tr.show-for-large-up, tr.show-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.show-for-large-only, td.show-for-large-only, th.show-for-large-up, td.show-for-large-up, th.show-for-large, td.show-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* xlarge displays */
@media only screen and (min-width: 90.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.show-for-xlarge-only, table.show-for-xlarge-up, table.show-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.show-for-xlarge-only, thead.show-for-xlarge-up, thead.show-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.show-for-xlarge-only, tbody.show-for-xlarge-up, tbody.show-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.show-for-xlarge-only, tr.show-for-xlarge-up, tr.show-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.show-for-xlarge-only, td.show-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.show-for-xlarge, td.show-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* xxlarge displays */
@media only screen and (min-width: 120.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .hide-for-xlarge-only, .show-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .show-for-xlarge-only, .hide-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .hidden-for-xlarge-only, .visible-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .visible-for-xlarge-only, .hidden-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.hide-for-xlarge-only, table.show-for-xlarge-up, table.hide-for-xlarge, table.hide-for-xlarge-down, table.show-for-xxlarge-only, table.show-for-xxlarge-up, table.show-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.hide-for-xlarge-only, thead.show-for-xlarge-up, thead.hide-for-xlarge, thead.hide-for-xlarge-down, thead.show-for-xxlarge-only, thead.show-for-xxlarge-up, thead.show-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.hide-for-xlarge-only, tbody.show-for-xlarge-up, tbody.hide-for-xlarge, tbody.hide-for-xlarge-down, tbody.show-for-xxlarge-only, tbody.show-for-xxlarge-up, tbody.show-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.hide-for-xlarge-only, tr.show-for-xlarge-up, tr.hide-for-xlarge, tr.hide-for-xlarge-down, tr.show-for-xxlarge-only, tr.show-for-xxlarge-up, tr.show-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.hide-for-xlarge-down, td.hide-for-xlarge-down, th.show-for-xxlarge-only, td.show-for-xxlarge-only, th.show-for-xxlarge-up, td.show-for-xxlarge-up, th.show-for-xxlarge, td.show-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
  display: inherit !important; }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }

/* Specific visibility for tables */
table.hide-for-landscape, table.show-for-portrait {
  display: table !important; }

thead.hide-for-landscape, thead.show-for-portrait {
  display: table-header-group !important; }

tbody.hide-for-landscape, tbody.show-for-portrait {
  display: table-row-group !important; }

tr.hide-for-landscape, tr.show-for-portrait {
  display: table-row !important; }

td.hide-for-landscape, td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important; }

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important; }
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important; }
  /* Specific visibility for tables */
  table.show-for-landscape, table.hide-for-portrait {
    display: table !important; }
  thead.show-for-landscape, thead.hide-for-portrait {
    display: table-header-group !important; }
  tbody.show-for-landscape, tbody.hide-for-portrait {
    display: table-row-group !important; }
  tr.show-for-landscape, tr.hide-for-portrait {
    display: table-row !important; }
  td.show-for-landscape, td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important; } }

@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important; }
  .hide-for-portrait,
  .show-for-landscape {
    display: none !important; }
  /* Specific visibility for tables */
  table.show-for-portrait, table.hide-for-landscape {
    display: table !important; }
  thead.show-for-portrait, thead.hide-for-landscape {
    display: table-header-group !important; }
  tbody.show-for-portrait, tbody.hide-for-landscape {
    display: table-row-group !important; }
  tr.show-for-portrait, tr.hide-for-landscape {
    display: table-row !important; }
  td.show-for-portrait, td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important; } }

/* Touch-enabled device targeting */
.show-for-touch {
  display: none !important; }

.hide-for-touch {
  display: inherit !important; }

.touch .show-for-touch {
  display: inherit !important; }

.touch .hide-for-touch {
  display: none !important; }

/* Specific visibility for tables */
table.hide-for-touch {
  display: table !important; }

.touch table.show-for-touch {
  display: table !important; }

thead.hide-for-touch {
  display: table-header-group !important; }

.touch thead.show-for-touch {
  display: table-header-group !important; }

tbody.hide-for-touch {
  display: table-row-group !important; }

.touch tbody.show-for-touch {
  display: table-row-group !important; }

tr.hide-for-touch {
  display: table-row !important; }

.touch tr.show-for-touch {
  display: table-row !important; }

td.hide-for-touch {
  display: table-cell !important; }

.touch td.show-for-touch {
  display: table-cell !important; }

th.hide-for-touch {
  display: table-cell !important; }

.touch th.show-for-touch {
  display: table-cell !important; }

/* Screen reader-specific classes */
.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }

.show-on-focus {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }
  .show-on-focus:focus, .show-on-focus:active {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }

/* Print visibility */
.print-only,
.show-for-print {
  display: none !important; }

@media print {
  .print-only,
  .show-for-print {
    display: block !important; }
  .hide-on-print,
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; } }

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 -0.625rem; }
  [class*="block-grid-"]:before, [class*="block-grid-"]:after {
    content: " ";
    display: table; }
  [class*="block-grid-"]:after {
    clear: both; }
  [class*="block-grid-"] > li {
    display: block;
    float: left;
    height: auto;
    padding: 0 0.625rem 1.25rem; }

@media only screen {
  .small-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .small-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .small-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .small-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .small-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%; }
    .small-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .small-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .small-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .small-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .small-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .small-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%; }
    .small-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .small-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%; }
    .small-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .small-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .small-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .small-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%; }
    .small-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .small-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .small-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .small-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%; }
    .small-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .small-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%; }
    .small-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

@media only screen and (min-width: 40.0625em) {
  .medium-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .medium-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .medium-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .medium-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .medium-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%; }
    .medium-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .medium-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .medium-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .medium-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .medium-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .medium-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%; }
    .medium-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .medium-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%; }
    .medium-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .medium-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .medium-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .medium-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%; }
    .medium-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .medium-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .medium-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .medium-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%; }
    .medium-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%; }
    .medium-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

@media only screen and (min-width: 80.0625em) {
  .large-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .large-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .large-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .large-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .large-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%; }
    .large-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .large-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .large-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .large-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .large-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .large-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%; }
    .large-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .large-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%; }
    .large-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .large-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .large-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .large-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%; }
    .large-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .large-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .large-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .large-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%; }
    .large-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .large-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%; }
    .large-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Thin.woff") format("woff"), url("../fonts/Roboto-Thin.ttf") format("truetype"), url("../fonts/Roboto-Thin.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"), url("../fonts/Roboto-Light.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto-Medium.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype"), url("../fonts/Roboto-Black.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-ThinItalic.woff") format("woff"), url("../fonts/Roboto-ThinItalic.ttf") format("truetype"), url("../fonts/Roboto-ThinItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-LightItalic.woff") format("woff"), url("../fonts/Roboto-LightItalic.ttf") format("truetype"), url("../fonts/Roboto-LightItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-RegularItalic.woff") format("woff"), url("../fonts/Roboto-RegularItalic.ttf") format("truetype"), url("../fonts/Roboto-RegularItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-MediumItalic.woff") format("woff"), url("../fonts/Roboto-MediumItalic.ttf") format("truetype"), url("../fonts/Roboto-MediumItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BoldItalic.woff") format("woff"), url("../fonts/Roboto-BoldItalic.ttf") format("truetype"), url("../fonts/Roboto-BoldItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BlackItalic.woff") format("woff"), url("../fonts/Roboto-BlackItalic.ttf") format("truetype"), url("../fonts/Roboto-BlackItalic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: handicare;
  src: url("../fonts/handicare.eot?#iefix") format("embedded-opentype"), url("../fonts/handicare.woff") format("woff"), url("../fonts/handicare.ttf") format("truetype"), url("../fonts/handicare.svg#handicare") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

.icon {
  font-family: handicare, sans-serif;
  text-transform: none; }

h1, h2, h3, h4, h5, h6 {
  font-family: Roboto, serif;
  text-align: left;
  font-weight: 300;
  text-align: center; }
  h1.sub, h2.sub, h3.sub, h4.sub, h5.sub, h6.sub {
    font-weight: 400; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1.125rem; }

h4 {
  font-size: 3.75rem; }

h5 {
  font-size: 0.75rem; }

h6 {
  font-size: 0.625rem; }

.font-Thin {
  font-weight: 100; }

.font-Light {
  font-weight: 300; }

.font-Regular {
  font-weight: 400; }

.font-Medium {
  font-weight: 500; }

.font-Bold {
  font-weight: 700; }

.font-Black {
  font-weight: 900; }

.font-bold, strong {
  font-weight: 700; }

small {
  font-size: smaller; }

del {
  text-decoration: line-through; }

ins {
  text-decoration: underline; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: sup;
  font-size: smaller; }

p {
  margin: 0;
  padding: 0; }
  p.text-tiny {
    font-size: 0.75rem; }
  p.text-small {
    font-size: 0.875rem; }
  p.text-regular {
    font-size: 1rem; }
  p.text-large {
    font-size: 1.125rem; }
  p.text-xlarge {
    font-size: 1.5rem; }

h2 {
  margin-top: 22px; }

h2 + p {
  margin-top: 3.75rem; }
  @media only screen and (max-width: 321px) {
    h2 + p {
      margin-top: 1rem; } }

p + p {
  margin-top: 1rem; }

p + .buttonHolder {
  margin-top: 2.5rem; }
  @media only screen and (max-width: 321px) {
    p + .buttonHolder {
      margin-top: 1rem; } }

ul + .buttonHolder {
  margin-top: 1rem; }
  @media only screen and (max-width: 321px) {
    ul + .buttonHolder {
      margin-top: .5rem; } }

*:before {
  content: none; }

.makeRelative {
  position: relative; }

.textCenter {
  text-align: center; }

.textLeft {
  text-align: left; }

.textRight {
  text-align: right; }

nav li {
  display: inline-block; }

hr.clear {
  width: 100%;
  height: 0px;
  clear: both;
  background: none;
  border: none;
  color: none; }

.state-login .inputHolder:after, .state-users .inputHolder:after, .state-admins .inputHolder:after, .state-settings .inputHolder:after {
  width: 100%;
  height: 0px;
  clear: both;
  background: none;
  border: none;
  color: none; }

[class*="column"] + [class*="column"]:last-child {
  float: left !important; }

a {
  text-decoration: none;
  color: inherit; }

* {
  -webkit-appearance: none;
  outline: none;
  outline: none;
  border-box: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent; }
  *:focus {
    outline: none; }
  *:invalid {
    box-shadow: none; }
  *:-moz-submit-invalid {
    box-shadow: none; }
  *:-moz-ui-invalid {
    box-shadow: none; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

body, html {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  color: #231f20;
  font-weight: 300;
  font-family: Roboto, serif;
  font-size: 16px; }
  .touch body, .touch html {
    overflow-x: hidden; }
  body header, body section, body footer, html header, html section, html footer {
    font-size: 0.875rem; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  height: 100%;
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

ul.slick-dots {
  font-size: 0;
  text-align: center;
  padding-top: 3rem; }
  ul.slick-dots li {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: transparent;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    margin: 0 .5rem;
    position: relative; }
    ul.slick-dots li:before {
      content: "";
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      background: blue;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.25);
      transition: 0.3s; }
    ul.slick-dots li.slick-active:before {
      transform: translate(-50%, -50%) scale(1);
      transition: 0.3s; }
    ul.slick-dots li button {
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0; }

body {
  background-color: #231f20; }
  @media only screen and (max-width: 40em) {
    body {
      overflow: hidden; } }

strong {
  font-weight: 900 !important; }
  strong span {
    font-weight: 900 !important; }

.button {
  cursor: pointer;
  white-space: nowrap;
  position: relative; }
  .button span {
    font-weight: 400; }
  .button.naked {
    opacity: .1; }
    .button.naked.icon-previous {
      opacity: 1; }
    .button.naked:hover {
      opacity: .25; }
  .button:not(.naked) {
    height: 2.5rem;
    padding: 0 1rem;
    line-height: 2.5rem;
    font-size: 1rem;
    display: inline-block;
    border-radius: 2px;
    position: relative; }
  .button.active {
    opacity: 1; }
  .button.orange {
    background: #f08e1c;
    color: #FFF;
    position: relative;
    top: 10px;
    opacity: 0;
    transition: 0.3s; }
    .button.orange.active {
      top: 0px;
      opacity: 1;
      transition: 0.3s; }
    .button.orange:hover {
      background: #f29a34; }
  .button.icon-next {
    padding-right: 2rem; }
    .button.icon-next:after {
      content: "b";
      transform: rotate(180deg);
      text-align: right;
      font-family: handicare, sans-serif;
      display: block;
      width: 1.5rem;
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%; }
    .button.icon-next.orange:after {
      font-size: .75rem; }
  .button.icon-previous {
    padding-left: 2rem; }
    .button.icon-previous:before {
      content: "b";
      font-family: handicare, sans-serif;
      text-align: right;
      display: block;
      width: 1.5rem;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%; }
  .button.arrow {
    padding-left: 1.5rem;
    margin-left: .5rem; }
    .button.arrow.arrow-up:before {
      content: "b";
      font-family: handicare, sans-serif;
      text-align: right;
      display: block;
      width: 1.5rem;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 50%;
      transform: rotate(90deg) translateX(40%);
      text-align: center;
      line-height: 1rem; }
    .button.arrow.arrow-down:before {
      content: "b";
      font-family: handicare, sans-serif;
      text-align: right;
      display: block;
      width: 1.5rem;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 50%;
      transform: rotate(-90deg) translateX(-40%);
      text-align: center;
      line-height: 1rem; }

.buttonHolder {
  text-align: center;
  width: 100%;
  display: block; }

.formArea {
  width: 100%;
  bottom: 0rem;
  position: absolute;
  background: #4a575f;
  color: #FFF; }
  .formArea .row.form {
    text-align: center; }
  .formArea .stepNavigation {
    position: relative;
    width: 100%;
    padding: 1rem 0rem; }
    @media only screen and (max-width: 321px) {
      .formArea .stepNavigation {
        padding: .5rem 0; } }
    .formArea .stepNavigation .columns:nth-child(1), .formArea .stepNavigation .column:nth-child(1) {
      text-align: left;
      padding-left: 1rem; }
      .formArea .stepNavigation .columns:nth-child(1) .button, .formArea .stepNavigation .column:nth-child(1) .button {
        float: left; }
    .formArea .stepNavigation .columns:nth-child(2), .formArea .stepNavigation .column:nth-child(2) {
      text-align: center; }
    .formArea .stepNavigation .columns:nth-child(3), .formArea .stepNavigation .column:nth-child(3) {
      text-align: right;
      padding-right: 1rem; }
      .formArea .stepNavigation .columns:nth-child(3) .button, .formArea .stepNavigation .column:nth-child(3) .button {
        float: right; }

input, textarea, select {
  border: none;
  border-radius: 2px;
  padding: 0 0 0 .5rem;
  max-width: 100%; }

input, select {
  height: 2.5rem; }

span.measure {
  color: #4a575f; }

input:disabled {
  opacity: .25;
  transition: 0.3s; }

.form .oneInput, .form .twoInputs, .form .threeInputs, .form .longInputs {
  font-size: 0;
  padding: 1.5rem 1.5rem 0 1.5rem; }
  @media only screen and (max-width: 321px) {
    .form .oneInput, .form .twoInputs, .form .threeInputs, .form .longInputs {
      padding-top: 1rem; } }
  .form .oneInput .column, .form .twoInputs .column, .form .threeInputs .column, .form .longInputs .column {
    position: relative; }
  .form .oneInput label, .form .oneInput span.measure, .form .twoInputs label, .form .twoInputs span.measure, .form .threeInputs label, .form .threeInputs span.measure, .form .longInputs label, .form .longInputs span.measure {
    width: 2rem;
    height: 2.5rem;
    display: inline-block;
    text-align: center;
    line-height: 3rem; }
  .form .oneInput input, .form .twoInputs input, .form .threeInputs input, .form .longInputs input {
    border-radius: 0 2px 2px 0;
    display: inline-block;
    width: -moz-calc(100% - 2rem);
    width: -webkit-calc(100% - 2rem);
    width: calc(100% - 2rem);
    position: relative;
    top: -4px;
    text-align: right;
    padding-right: 2rem; }
    .Explorer .form .oneInput input, .Explorer .form .twoInputs input, .Explorer .form .threeInputs input, .Explorer .form .longInputs input {
      left: -1px; }
  .form .oneInput label, .form .twoInputs label, .form .threeInputs label, .form .longInputs label {
    background: #f08e1c;
    font-size: 1rem;
    border-radius: 2px 0 0 2px;
    color: white;
    position: relative;
    top: -2px;
    line-height: 2.5rem; }
    .form .oneInput label.degr, .form .twoInputs label.degr, .form .threeInputs label.degr, .form .longInputs label.degr {
      font-size: 1.5rem; }
  .form .oneInput span.measure, .form .twoInputs span.measure, .form .threeInputs span.measure, .form .longInputs span.measure {
    font-size: 0.625rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    line-height: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    width: 2rem;
    text-align: center;
    background: none;
    color: gray;
    border-radius: 0 2px 2px 0;
    line-height: 2.9rem; }
    .form .oneInput span.measure span, .form .twoInputs span.measure span, .form .threeInputs span.measure span, .form .longInputs span.measure span {
      font-size: 0.625rem;
      font-weight: 900; }

.form .twoInputs .column, .form .threeInputs .column {
  margin-right: .75rem; }

.form .twoInputs label, .form .threeInputs label {
  position: absolute;
  border-radius: 2px;
  left: 0rem;
  top: auto;
  bottom: 35px;
  z-index: 100;
  size: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase; }
  .Explorer .form .twoInputs label, .Explorer .form .threeInputs label {
    margin-left: -1px; }

.form .twoInputs input, .form .threeInputs input {
  border-radius: 2px;
  width: -moz-calc(100% - 1.95rem);
  width: -webkit-calc(100% - 1.95rem);
  width: calc(100% - 1.95rem);
  width: 100%;
  top: 0px;
  padding-right: 2rem;
  left: 0rem; }

.form .twoInputs span.measure, .form .threeInputs span.measure {
  position: absolute;
  right: 0rem;
  height: 2.5rem;
  top: auto;
  bottom: 0; }

.form .twoInputs .column {
  width: -moz-calc(50% - .5rem);
  width: -webkit-calc(50% - .5rem);
  width: calc(50% - .5rem); }
  .form .twoInputs .column:nth-child(2) {
    margin-right: 0; }

.form .threeInputs .column {
  width: -moz-calc(33.33% - .5rem);
  width: -webkit-calc(33.33% - .5rem);
  width: calc(33.33% - .5rem); }
  .form .threeInputs .column:nth-child(3) {
    margin-right: 0; }

.form .longInputs {
  text-align: left; }
  .form .longInputs .column {
    padding-bottom: .5rem;
    font-size: 0rem; }
  .form .longInputs label {
    width: auto;
    background: none;
    text-align: left;
    font-size: 1rem;
    white-space: nowrap; }
  .form .longInputs input {
    width: 100%;
    border-radius: 2px; }
  .form .longInputs span.measure {
    right: 0px;
    top: -.25rem; }

.switch {
  display: inline-block;
  font-size: 0rem;
  width: 100%;
  text-align: center;
  border: 1px solid #ccc; }
  .switch span {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 1rem .5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #231f20; }
    .switch span:before {
      content: "";
      width: .6rem;
      height: .3rem;
      position: relative;
      transform: translate(-50%, -50%) scale(0) rotate(-45deg);
      background: transparent;
      border-left: 2px solid #FFF;
      border-bottom: 2px solid #FFF;
      margin-right: 0rem;
      opacity: 0;
      display: inline-block;
      top: -.2rem; }
    .switch span:after {
      content: attr(title);
      position: relative;
      z-index: 200; }
      @media only screen and (max-width: 40em) {
        .switch span:after {
          content: attr(short); } }
    .switch span:first-child {
      border-radius: 2px 0 0 2px; }
    .switch span:last-child {
      border-radius: 0 2px 2px 0; }
    .switch span:nth-child(1):nth-last-child(1) {
      width: 100%; }
    .switch span:nth-child(1):nth-last-child(2), .switch span:nth-child(2):nth-last-child(1) {
      width: 50%; }
    .switch span:nth-child(1):nth-last-child(3), .switch span:nth-child(2):nth-last-child(2), .switch span:nth-child(3):nth-last-child(1) {
      width: 33.3333333333%; }
    .switch span:nth-child(1):nth-last-child(4), .switch span:nth-child(2):nth-last-child(3), .switch span:nth-child(3):nth-last-child(2), .switch span:nth-child(4):nth-last-child(1) {
      width: 25%; }
    .switch span:nth-child(1):nth-last-child(5), .switch span:nth-child(2):nth-last-child(4), .switch span:nth-child(3):nth-last-child(3), .switch span:nth-child(4):nth-last-child(2), .switch span:nth-child(5):nth-last-child(1) {
      width: 20%; }
    .switch span:nth-child(1):nth-last-child(6), .switch span:nth-child(2):nth-last-child(5), .switch span:nth-child(3):nth-last-child(4), .switch span:nth-child(4):nth-last-child(3), .switch span:nth-child(5):nth-last-child(2), .switch span:nth-child(6):nth-last-child(1) {
      width: 16.6666666667%; }
    .switch span:nth-child(1):nth-last-child(7), .switch span:nth-child(2):nth-last-child(6), .switch span:nth-child(3):nth-last-child(5), .switch span:nth-child(4):nth-last-child(4), .switch span:nth-child(5):nth-last-child(3), .switch span:nth-child(6):nth-last-child(2), .switch span:nth-child(7):nth-last-child(1) {
      width: 14.2857142857%; }
    .switch span:nth-child(1):nth-last-child(8), .switch span:nth-child(2):nth-last-child(7), .switch span:nth-child(3):nth-last-child(6), .switch span:nth-child(4):nth-last-child(5), .switch span:nth-child(5):nth-last-child(4), .switch span:nth-child(6):nth-last-child(3), .switch span:nth-child(7):nth-last-child(2), .switch span:nth-child(8):nth-last-child(1) {
      width: 12.5%; }
    .switch span:nth-child(1):nth-last-child(9), .switch span:nth-child(2):nth-last-child(8), .switch span:nth-child(3):nth-last-child(7), .switch span:nth-child(4):nth-last-child(6), .switch span:nth-child(5):nth-last-child(5), .switch span:nth-child(6):nth-last-child(4), .switch span:nth-child(7):nth-last-child(3), .switch span:nth-child(8):nth-last-child(2), .switch span:nth-child(9):nth-last-child(1) {
      width: 11.1111111111%; }
    .switch span:nth-child(1):nth-last-child(10), .switch span:nth-child(2):nth-last-child(9), .switch span:nth-child(3):nth-last-child(8), .switch span:nth-child(4):nth-last-child(7), .switch span:nth-child(5):nth-last-child(6), .switch span:nth-child(6):nth-last-child(5), .switch span:nth-child(7):nth-last-child(4), .switch span:nth-child(8):nth-last-child(3), .switch span:nth-child(9):nth-last-child(2), .switch span:nth-child(10):nth-last-child(1) {
      width: 10%; }
  .switch[data-switch="0"] span:nth-child(0) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="0"] span:nth-child(0):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="1"] span:nth-child(1) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="1"] span:nth-child(1):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="2"] span:nth-child(2) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="2"] span:nth-child(2):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="3"] span:nth-child(3) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="3"] span:nth-child(3):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="4"] span:nth-child(4) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="4"] span:nth-child(4):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="5"] span:nth-child(5) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="5"] span:nth-child(5):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="6"] span:nth-child(6) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="6"] span:nth-child(6):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="7"] span:nth-child(7) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="7"] span:nth-child(7):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="8"] span:nth-child(8) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="8"] span:nth-child(8):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="9"] span:nth-child(9) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="9"] span:nth-child(9):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }
  .switch[data-switch="10"] span:nth-child(10) {
    font-weight: 700;
    color: #FFF;
    background: #f08e1c; }
    .switch[data-switch="10"] span:nth-child(10):before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(-45deg);
      transition: 0.3s; }

header .settings, header .settings a {
  overflow: hidden;
  position: absolute;
  top: 0px;
  height: 3rem;
  z-index: 120;
  right: 0px;
  width: 3rem;
  text-indent: -999em;
  text-align: left; }
  header .settings a:before, header .settings a:after, header .settings a a:before, header .settings a a:after {
    content: "";
    width: 1rem;
    height: 0.125rem;
    background: #f08e1c;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%); }
  header .settings a:before, header .settings a a:before {
    transform: translate(-50%) rotate(45deg); }
  header .settings a:after, header .settings a a:after {
    transform: translate(-50%) rotate(-45deg); }
  .state-home header .settings a:before, .state-home header .settings a a:before {
    content: "a";
    display: block;
    background: transparent;
    transform: none;
    top: 0;
    left: 0;
    grwidth: 100%;
    height: 3rem;
    text-indent: 0;
    line-height: 3rem;
    font-family: handicare, sans-serif;
    color: #f08e1c;
    font-size: 1rem;
    text-align: center; }
    .state-home header .settings a:before:hover, .state-home header .settings a a:before:hover {
      transform: rotate(180deg);
      transition: 0.3s; }
  .state-home header .settings a:after, .state-home header .settings a a:after {
    content: none; }

section {
  background: #FFF; }
  section[class^="step"], section[class*=" step"] {
    position: fixed;
    min-height: 344px;
    top: 0rem;
    left: 0px;
    width: 100%;
    height: 100vh;
    height: -moz-calc(100% - 3rem);
    height: -webkit-calc(100% - 3rem);
    height: calc(100% - 3rem);
    z-index: 10;
    opacity: 0; }
    section[class^="step"].active, section[class*=" step"].active {
      top: 0rem;
      opacity: 1;
      z-index: 100; }
  section .content {
    padding: 0 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    overflow: auto; }
  section h2, section h2 span {
    font-size: 1.5rem;
    font-weight: 300; }
  section h3 {
    font-size: 1rem;
    color: #f08e1c; }
  @media only screen and (max-width: 40em) {
    .keyboard section {
      height: 100%; }
      .keyboard section .content {
        height: 100%;
        min-height: 200px; }
        .keyboard section .content h2 {
          position: relative;
          top: -200px;
          transition: 0.3s;
          opacity: 0; } }
  section .illustration {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 240px;
    height: 100%;
    height: -moz-calc(100% - 3rem);
    height: -webkit-calc(100% - 3rem);
    height: calc(100% - 3rem);
    top: 3rem;
    text-indent: -999em;
    display: block;
    transition: 0.3s; }
    .keyboard section .illustration {
      top: 0px;
      height: 100%;
      transform: scale(0.75);
      transition: 0.3s; }
      @media only screen and (max-width: 321px) {
        .keyboard section .illustration {
          transform: scale(0.5);
          height: 240px;
          top: -50px; } }
    section .illustration span[class^="side"], section .illustration span[class*="side"] {
      display: block;
      height: 100%;
      width: 4rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      section .illustration span[class^="side"]:before, section .illustration span[class^="side"]:after, section .illustration span[class*="side"]:before, section .illustration span[class*="side"]:after {
        content: "";
        display: block;
        width: 2px;
        height: 0%;
        position: absolute;
        top: 0px;
        background: #f08e1c;
        transition: 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67); }
      section .illustration span[class^="side"]:before, section .illustration span[class*="side"]:before {
        left: 0px; }
      section .illustration span[class^="side"]:after, section .illustration span[class*="side"]:after {
        right: 0px; }
      section .illustration span[class^="side"] span:first-child, section .illustration span[class*="side"] span:first-child {
        width: 1rem;
        height: 1rem;
        background: #f08e1c;
        border-radius: 2px;
        display: block;
        position: absolute;
        font-weight: bold;
        font-size: .8rem;
        color: white;
        font-weight: 700;
        line-height: 1rem;
        text-indent: 0;
        text-align: center;
        z-index: 10; }
      section .illustration span[class^="side"] span:last-child, section .illustration span[class*="side"] span:last-child {
        width: 0%;
        height: 2px;
        display: block;
        position: absolute;
        width: -moz-calc(100% - 16px);
        width: -webkit-calc(100% - 16px);
        width: calc(100% - 16px);
        background: #f08e1c;
        animation: growArrow 3s;
        transition: 1s; }
        section .illustration span[class^="side"] span:last-child:before, section .illustration span[class^="side"] span:last-child:after, section .illustration span[class*="side"] span:last-child:before, section .illustration span[class*="side"] span:last-child:after {
          content: "";
          width: .45rem;
          height: .45rem;
          background: transparent;
          display: block;
          position: absolute;
          top: 50%;
          transform: rotate(-135deg);
          margin-top: -4px; }
        section .illustration span[class^="side"] span:last-child:before, section .illustration span[class*="side"] span:last-child:before {
          left: 0px;
          border-right: 2px solid #f08e1c;
          border-top: 2px solid #f08e1c; }
        section .illustration span[class^="side"] span:last-child:after, section .illustration span[class*="side"] span:last-child:after {
          right: 0px;
          border-left: 2px solid #f08e1c;
          border-bottom: 2px solid #f08e1c; }
      section .illustration span[class^="side"] span, section .illustration span[class*="side"] span {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    section .illustration.stairC .sideA {
      margin-left: -47px;
      height: 280px;
      margin-top: 56px;
      width: 145px; }
      section .illustration.stairC .sideA:before, section .illustration.stairC .sideA:after {
        height: 100%; }
      section .illustration.stairC .sideA span {
        margin-top: 140px; }
    section .illustration.stairC .sideB {
      height: 260px;
      margin-left: 67px;
      width: 65px;
      margin-top: -16px; }
      section .illustration.stairC .sideB:before {
        height: 61%; }
      section .illustration.stairC .sideB:after {
        height: 100%; }
      section .illustration.stairC .sideB span {
        margin-top: 0px; }
    section .illustration.stairC .sideC {
      display: none; }
    section .illustration.stairD .sideA {
      margin-left: -75px;
      height: 180px;
      margin-top: 75px;
      width: 90px; }
      section .illustration.stairD .sideA:before, section .illustration.stairD .sideA:after {
        height: 100%; }
      section .illustration.stairD .sideA span {
        margin-top: 80px; }
    section .illustration.stairD .sideB {
      height: 230px;
      margin-left: 66px;
      width: 110px;
      margin-top: -20px; }
      section .illustration.stairD .sideB:before {
        height: 90%; }
      section .illustration.stairD .sideB:after {
        height: 100%; }
      section .illustration.stairD .sideB span {
        margin-top: 20px; }
    section .illustration.stairD .sideC {
      display: none; }
    section .illustration.stairE .sideA {
      margin-left: 65px;
      height: 120px;
      margin-top: 110px;
      width: 45px; }
      section .illustration.stairE .sideA:before, section .illustration.stairE .sideA:after {
        height: 100%; }
      section .illustration.stairE .sideA span {
        margin-top: 60px; }
    section .illustration.stairE .sideB {
      height: 200px;
      margin-left: 5px;
      width: 66px;
      margin-top: 30px; }
      section .illustration.stairE .sideB:after {
        height: 67%; }
      section .illustration.stairE .sideB:before {
        height: 100%; }
      section .illustration.stairE .sideB span {
        margin-top: 10px; }
    section .illustration.stairE .sideC {
      margin-left: -49px;
      height: 315px;
      margin-top: -8px;
      width: 45px; }
      section .illustration.stairE .sideC:before {
        height: 100%; }
      section .illustration.stairE .sideC:after {
        height: 90%; }
      section .illustration.stairE .sideC span {
        margin-top: 0px; }
    section .illustration.stairF .sideA {
      margin-left: -30px;
      height: 200px;
      margin-top: 80px;
      width: 73px; }
      section .illustration.stairF .sideA:before, section .illustration.stairF .sideA:after {
        height: 100%; }
      section .illustration.stairF .sideA span {
        margin-top: 100px; }
    section .illustration.stairF .sideB {
      height: 260px;
      margin-left: 67px;
      width: 108px;
      margin-top: -16px; }
      section .illustration.stairF .sideB:before {
        height: 61%; }
      section .illustration.stairF .sideB:after {
        height: 100%; }
      section .illustration.stairF .sideB span {
        margin-top: 0px; }
    section .illustration.stairF .sideC {
      display: none; }
    section .illustration.stairG .sideA {
      margin-left: 65px;
      height: 120px;
      margin-top: 110px;
      width: 45px; }
      section .illustration.stairG .sideA:before, section .illustration.stairG .sideA:after {
        height: 100%; }
      section .illustration.stairG .sideA span {
        margin-top: 60px; }
    section .illustration.stairG .sideB {
      height: 160px;
      margin-left: -49px;
      width: 46px;
      margin-top: 70px; }
      section .illustration.stairG .sideB:before {
        height: 100%; }
      section .illustration.stairG .sideB:after {
        height: 85%; }
      section .illustration.stairG .sideB span {
        margin-top: 20px; }
    section .illustration.stairG .sideC {
      display: none; }
    section .illustration.stairH .sideA {
      margin-left: 65px;
      height: 120px;
      margin-top: 110px;
      width: 45px; }
      section .illustration.stairH .sideA:before, section .illustration.stairH .sideA:after {
        height: 100%; }
      section .illustration.stairH .sideA span {
        margin-top: 80px; }
    section .illustration.stairH .sideB {
      height: 200px;
      margin-left: 5px;
      width: 66px;
      margin-top: 30px; }
      section .illustration.stairH .sideB:after {
        height: 67%; }
      section .illustration.stairH .sideB:before {
        height: 100%; }
      section .illustration.stairH .sideB span {
        margin-top: 10px; }
    section .illustration.stairH .sideC {
      margin-left: -49px;
      height: 255px;
      margin-top: 25px;
      width: 45px; }
      section .illustration.stairH .sideC:before {
        height: 100%; }
      section .illustration.stairH .sideC:after {
        height: 90%; }
      section .illustration.stairH .sideC span {
        margin-top: -10px; }
  section#intro {
    background: #FFF; }
  section#step1 .illustration {
    background-image: url("../img/stairWidth.svg"); }
  section#step2 .choices {
    padding: 0; }
    section#step2 .choices ul[class*="block-grid-"] > li {
      padding: 0;
      padding-bottom: 12px; }
      @media only screen and (min-width: 40.0625em) {
        section#step2 .choices ul[class*="block-grid-"] > li:nth-child(3n-2) {
          padding-right: 12px; }
        section#step2 .choices ul[class*="block-grid-"] > li:nth-child(3n) {
          padding-left: 12px; } }
      @media only screen and (max-width: 40em) {
        section#step2 .choices ul[class*="block-grid-"] > li:nth-child(2n-1) {
          padding-right: 6px; }
        section#step2 .choices ul[class*="block-grid-"] > li:nth-child(2n) {
          padding-left: 6px; } }
      @media only screen and (max-width: 321px) {
        section#step2 .choices ul[class*="block-grid-"] > li {
          padding-bottom: .5rem; }
          section#step2 .choices ul[class*="block-grid-"] > li:nth-child(2n-1) {
            padding-right: .25rem; }
          section#step2 .choices ul[class*="block-grid-"] > li:nth-child(2n) {
            padding-left: .25rem; } }
    section#step2 .choices ul li div {
      cursor: pointer;
      position: relative;
      border: 2px solid #edeeef;
      transition: 0.3s;
      border-radius: 2px;
      background-size: 80px;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 5.5rem;
      /*             &[title="E"],&[title="H"],&[title="G"],{
  span{ display: none; }
} */ }
      @media only screen and (min-width: 40.0625em) {
        section#step2 .choices ul li div {
          min-height: 10rem; } }
      @media only screen and (max-width: 321px) {
        section#step2 .choices ul li div {
          min-height: 4.5rem; } }
      section#step2 .choices ul li div:hover {
        border: 2px solid #d2d5d7;
        transition: 0.3s; }
      section#step2 .choices ul li div:before {
        content: attr(title);
        position: absolute;
        top: 50%;
        width: 30%;
        left: 0px;
        display: block;
        text-transform: uppercase;
        transform: translateY(-50%);
        text-align: center;
        font-weight: 700; }
      section#step2 .choices ul li div span.arrow {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        section#step2 .choices ul li div span.arrow:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: .5rem;
          height: .5rem;
          border-left: 2px solid #f08e1c;
          border-bottom: 2px solid #f08e1c;
          transform: translate(-50%, -50%); }
        section#step2 .choices ul li div span.arrow:after {
          content: "";
          display: block;
          width: 2px;
          height: .75rem;
          position: absolute;
          background: #f08e1c;
          left: 50%;
          top: 40%;
          transform: translateX(-50%); }
        section#step2 .choices ul li div span.arrow.arrow-up:before {
          transform: translate(-50%, -25%) rotate(135deg); }
        section#step2 .choices ul li div span.arrow.arrow-up:after {
          content: attr(title); }
        section#step2 .choices ul li div span.arrow.arrow-down {
          display: none; }
          section#step2 .choices ul li div span.arrow.arrow-down:before {
            transform: translate(-75%, -50%) rotate(-135deg); }
          section#step2 .choices ul li div span.arrow.arrow-down:after {
            content: attr(title); }
      section#step2 .choices ul li div[title="C"] span.arrow-up {
        margin: 36px 0 0 -12px; }
      section#step2 .choices ul li div[title="C"] span.arrow-down {
        margin: -15px 0 0 26px; }
      section#step2 .choices ul li div[title="D"] span.arrow-up {
        margin: 22px 0 0 -12px; }
      section#step2 .choices ul li div[title="D"] span.arrow-down {
        margin: -15px 0 0 38px; }
      section#step2 .choices ul li div[title="F"] span.arrow-up {
        margin: 36px 0 0 -12px; }
      section#step2 .choices ul li div[title="F"] span.arrow-down {
        margin: -15px 0 0 38px; }
      section#step2 .choices ul li div[title="E"] span.arrow-up, section#step2 .choices ul li div[title="H"] span.arrow-up, section#step2 .choices ul li div[title="G"] span.arrow-up {
        margin: 36px 0 0 -19px; }
      section#step2 .choices ul li div[title="E"] span.arrow-down, section#step2 .choices ul li div[title="H"] span.arrow-down, section#step2 .choices ul li div[title="G"] span.arrow-down {
        margin: 36px 0 0 18px; }
        section#step2 .choices ul li div[title="E"] span.arrow-down:before, section#step2 .choices ul li div[title="H"] span.arrow-down:before, section#step2 .choices ul li div[title="G"] span.arrow-down:before {
          transform: translate(-50%, -75%) rotate(-45deg); }
    section#step2 .choices ul li.active div {
      border: 2px solid #f08e1c;
      transition: 0.3s; }
  section#step5 .illustration {
    background-image: url("../img/bulkHead.svg"); }
    .keyboard section#step5 .illustration {
      background-size: 5rem; }
  section#results .illustration {
    top: 0rem;
    position: relative;
    height: 320px;
    transform: scale(0.75); }
  section#results .labels li {
    color: #4a575f; }
    section#results .labels li + li {
      margin-top: 20px; }
    section#results .labels li span.measure {
      padding-left: .25rem;
      display: inline-block;
      font-size: 0.7rem;
      font-weight: 900;
      color: #4a575f;
      text-transform: uppercase; }
    section#results .labels li label {
      font-weight: 900;
      color: #f08e1c;
      display: inline-block;
      padding-right: .5rem; }
    section#results .labels li ul li + li {
      margin-top: 0px; }
    section#results .labels li ul li label {
      color: #4a575f; }
    section#results .labels li.staircaseBulkheads ul li label {
      width: 9rem; }
  section#results h3 {
    font-weight: 300;
    font-size: 1.5rem; }
  section#results h3, section#results h4 {
    text-align: left; }
  section#results h4 {
    font-size: 1.25rem;
    line-height: 1rem;
    color: #f08e1c;
    font-weight: 700; }
  section#results .resultNo h3, section#results .resultNo p {
    color: red; }
  section#results .resultYes h3, section#results .resultYes p {
    color: green; }
  section#results .resultMaybe h3, section#results .resultMaybe p {
    color: #f08e1c; }
  section#login, section#admin, section.users, section.form {
    position: relative;
    top: auto; }
  section#login {
    top: 50%; }
    section#login .row.login {
      transform: translateY(-50%);
      text-align: right;
      width: 100%; }
      @media only screen and (max-width: 40em) {
        section#login .row.login {
          transform: none;
          position: relative;
          top: 0; } }

.state-login, .state-users, .state-admins, .state-settings {
  background: white; }
  .state-login input, .state-users input, .state-admins input, .state-settings input {
    width: 100%;
    text-align: right;
    text-align: left;
    display: inline-block;
    font-size: 1rem;
    height: 3rem;
    border: 1px solid #edeeef;
    padding: 0 1rem; }
    .state-login input[type=submit], .state-users input[type=submit], .state-admins input[type=submit], .state-settings input[type=submit] {
      border: 0px;
      background: transparent;
      color: #f08e1c;
      width: auto; }
    .state-login input[readonly], .state-users input[readonly], .state-admins input[readonly], .state-settings input[readonly] {
      border: 1px solid transparent;
      padding: 0;
      transition: 0.3s; }
  .state-login .inputHolder + .inputHolder, .state-login .inputHolder + .buttonHolder, .state-login .buttonHolder + .inputHolder, .state-login .buttonHolder + .buttonHolder, .state-users .inputHolder + .inputHolder, .state-users .inputHolder + .buttonHolder, .state-users .buttonHolder + .inputHolder, .state-users .buttonHolder + .buttonHolder, .state-admins .inputHolder + .inputHolder, .state-admins .inputHolder + .buttonHolder, .state-admins .buttonHolder + .inputHolder, .state-admins .buttonHolder + .buttonHolder, .state-settings .inputHolder + .inputHolder, .state-settings .inputHolder + .buttonHolder, .state-settings .buttonHolder + .inputHolder, .state-settings .buttonHolder + .buttonHolder {
    margin-top: 1rem; }
  .state-login .error, .state-users .error, .state-admins .error, .state-settings .error {
    display: block;
    text-align: left;
    font-size: .8rem;
    background: #edeeef;
    padding: .5rem;
    font-weight: 700;
    overflow: hidden;
    opacity: 0;
    max-height: 0px; }
  .state-login .ng-invalid.ng-submitted .error, .state-users .ng-invalid.ng-submitted .error, .state-admins .ng-invalid.ng-submitted .error, .state-settings .ng-invalid.ng-submitted .error {
    max-height: 1000px;
    opacity: 1; }
  .state-login .button, .state-users .button, .state-admins .button, .state-settings .button {
    font-size: 1.5rem; }
  .state-login h3, .state-users h3, .state-admins h3, .state-settings h3 {
    text-align: left;
    font-weight: 700; }
  .state-login h4, .state-users h4, .state-admins h4, .state-settings h4 {
    font-size: 1.5rem;
    display: block;
    text-align: left; }
  .state-login .buttonHolder, .state-users .buttonHolder, .state-admins .buttonHolder, .state-settings .buttonHolder {
    display: inline-block;
    text-align: right;
    width: auto;
    position: relative; }
    .state-login .buttonHolder:before, .state-users .buttonHolder:before, .state-admins .buttonHolder:before, .state-settings .buttonHolder:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 0.625rem;
      height: 0.625rem;
      top: 50%;
      left: 0px;
      border-right: 2px solid #f08e1c;
      border-bottom: 2px solid #f08e1c;
      transform: translate(-50%, -50%) rotate(-45deg);
      margin-top: 2px; }
  .state-login .inputHolder, .state-users .inputHolder, .state-admins .inputHolder, .state-settings .inputHolder {
    display: block; }
  .state-login td .button, .state-users td .button, .state-admins td .button, .state-settings td .button {
    font-size: .8rem;
    display: inline-block;
    color: #f08e1c;
    font-weight: 700; }

.sidebar {
  background: #f08e1c;
  width: 15rem;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%; }
  .state-login .sidebar {
    width: 50%; }
  .sidebar h1 {
    color: white;
    position: absolute;
    right: 0px;
    top: 0;
    padding-right: 1.875rem; }
    .state-login .sidebar h1 {
      top: 50%;
      transform: translateY(-50%); }
    .sidebar h1 span {
      display: block;
      text-align: right;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 300; }
  .sidebar nav {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%); }
    .sidebar nav ul {
      width: 100%; }
      .sidebar nav ul li {
        display: block;
        padding-right: 2rem; }
        .sidebar nav ul li a {
          color: #FFF;
          font-size: 1.5rem;
          text-align: right;
          display: block; }
  @media only screen and (max-width: 80em) {
    .sidebar {
      width: 7rem; }
      .state-login .sidebar {
        width: 50%; }
      .sidebar h1 {
        padding-right: 1rem; }
        .sidebar h1 span {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1rem; }
      .sidebar nav ul li {
        padding-right: 1rem; }
        .sidebar nav ul li a {
          font-size: 1rem;
          font-weight: 400; } }
  @media only screen and (max-width: 40em) {
    .sidebar {
      width: 100%;
      position: relative;
      height: 5rem;
      z-index: 100; }
      .sidebar h1 {
        left: 0px;
        right: auto;
        text-align: left;
        padding-left: 1rem;
        margin: 0;
        top: 50%;
        transform: translateY(-50%); }
        .sidebar h1 span {
          text-align: left; }
      .sidebar nav {
        right: 0px;
        width: auto; }
        .sidebar nav ul {
          width: auto; }
          .sidebar nav ul li {
            display: inline-block; }
      .state-login .sidebar {
        width: 100%; } }

main {
  width: -moz-calc(100% - 15rem);
  width: -webkit-calc(100% - 15rem);
  width: calc(100% - 15rem);
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: auto;
  height: 100%;
  padding: 0 4rem;
  z-index: 10; }
  .state-login main {
    width: 50%; }
  @media only screen and (min-width: 40.0625em) and (max-width: 80em) {
    main {
      width: -moz-calc(100% - 7rem);
      width: -webkit-calc(100% - 7rem);
      width: calc(100% - 7rem);
      padding: 0 2rem; } }
  @media only screen and (max-width: 40em) {
    main {
      width: 100%;
      position: relative;
      padding: 0 1rem;
      height: 100vh;
      margin-top: -5rem;
      padding-top: 6rem; }
      .state-login main {
        width: 100%; } }

table {
  width: 100%; }
  @media only screen and (max-width: 40em) {
    table thead {
      display: none; } }
  table thead tr td {
    font-size: 1rem;
    color: #ccc;
    font-weight: 700; }
  table tr td {
    font-size: 1rem;
    padding: .5rem 0; }
    table tr td:last-child {
      white-space: nowrap; }
    table tr td.role {
      white-space: nowrap; }
  table tr a.button.save {
    display: none; }
  table tr a.button.edit {
    display: block; }
  table tr.editing a.button.save {
    display: block; }
  table tr.editing a.button.edit {
    display: none; }
  @media only screen and (max-width: 40em) {
    table tr td {
      width: 100%;
      display: block;
      padding: 0;
      padding-left: 25%; }
      table tr td:before {
        content: attr(title);
        display: block;
        position: absolute;
        left: 0px;
        padding: .75rem 0 0 0;
        font-weight: 700;
        color: #ccc;
        font-size: 1rem;
        width: 100%; }
      table tr td:last-child {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #ccc;
        padding-left: 0; } }

.newUserForm {
  border-bottom: 1px solid #d2d5d7;
  padding-bottom: 30px; }

.userHeaderTooling {
  border-bottom: 1px solid #d2d5d7;
  padding-bottom: 15px; }

.userFooterTooling {
  border-top: 1px solid #d2d5d7;
  padding-top: 15px; }

.userPagination {
  line-height: 40px;
  height: 40px; }
  .userPagination a {
    color: #f08e1c;
    font-weight: bold; }

.searchInput {
  height: 40px !important; }

.searchButton {
  background-color: #f08e1c;
  color: #FFF;
  border: none;
  width: 100%; }

footer {
  background: #231f20;
  height: 3rem;
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 0.875rem;
  color: #FFF;
  width: 100%;
  line-height: 3rem;
  padding: 0 1.5rem; }
  .state-settings footer, .state-users footer, .state-admins footer, .state-login footer {
    display: none; }
  footer .copyright {
    opacity: .25; }
  footer .logo {
    display: block;
    height: 100%;
    width: 100%;
    text-indent: -999em;
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 100px;
    text-align: left; }
  @media only screen and (max-width: 40em) {
    .keyboard footer {
      max-height: 0px; } }

@-webkit-keyframes growArrow {
  0% {
    width: -moz-calc(100% - 4rem);
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem); }
  100% {
    width: -moz-calc(100% - 1rem);
    width: -webkit-calc(100% - 1rem);
    width: calc(100% - 1rem); } }

@-moz-keyframes growArrow {
  0% {
    width: -moz-calc(100% - 4rem);
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem); }
  100% {
    width: -moz-calc(100% - 1rem);
    width: -webkit-calc(100% - 1rem);
    width: calc(100% - 1rem); } }

@-ms-keyframes growArrow {
  0% {
    width: -moz-calc(100% - 4rem);
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem); }
  100% {
    width: -moz-calc(100% - 1rem);
    width: -webkit-calc(100% - 1rem);
    width: calc(100% - 1rem); } }

@keyframes growArrow {
  0% {
    width: -moz-calc(100% - 4rem);
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem); }
  100% {
    width: -moz-calc(100% - 1rem);
    width: -webkit-calc(100% - 1rem);
    width: calc(100% - 1rem); } }
